import React from "react"
import { Link } from "gatsby"
import { Router, Redirect } from '@reach/router'
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import Pictures from '../../../components/katalog/Pictures';


const ProjectsDone = (props) => {
    const { pageContext } = props;
    
  
  return <Layout pageContext={pageContext}>
    <SEO title="Шале" />
    <section id="home" className="hero hero_full_screen hero_parallax  text-center  _parallax-window" data-stellar-background-ratio="0.5">
      <div className="hero_parallax_inner">
        <div className="container aisle-work">
        <Pictures location={props.location} />
     
            {/* <Pictures path='/projects/:category/' />
            <Pictures path='/projects/:category/' />
    */}
        </div>
      </div>
    </section>
  </Layout>
}

export default ProjectsDone
